<template>
  <b-modal hide-footer v-model="showModal" size="md" title="QR Code" centered>
    <div class="text-center">
      <div class="w-100 px-md-5">
        <b-img
          v-if="imgQrCode"
          :src="imgQrCode"
          blank-color="#ffffff"
          class="w-100 h-100"
        />
        <font-awesome-icon v-else class="qr-code-none" icon="qrcode" />
      </div>
      <div class="mt-3 d-flex w-100">
        <div class="mx-auto">
          <b-button
            @click="download(imgQrCode)"
            variant="main-primary"
            class="ml-1"
            :disabled="isLoading || !imgQrCode"
            >Download</b-button
          >
        </div>
      </div>
    </div>
    <ModalLoading ref="modalLoading" :hasClose="false" />
  </b-modal>
</template>

<script>
import ModalLoading from "@/components/modal/alert/ModalLoading";
import { mapGetters } from "vuex";

export default {
  components: {
    ModalLoading
  },
  data() {
    return {
      showModal: false,
      isLoading: false,
      imgQrCode: null
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      brandId: "getBrand",
      adminProfile: "getChatAdminProfile"
    })
  },
  methods: {
    show() {
      this.getQrCode();
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    getQrCode: async function() {
      let body = {
        adminGuid: this.adminProfile.externalId,
        brandId: this.brandId
      };
      fetch(`${this.$chatBaseUrl}/user/get-qrcode-base64`, {
        method: "POST",
        headers: new Headers(this.$headersChat),
        body: JSON.stringify(body)
      })
        .then(response => response.json())
        .then(result => {
          if (result.result) {
            this.imgQrCode = `${result.detail}`;
          } else {
            this.$store.commit(
              "setMessageGlobalAlertError",
              result.detail || result.message
            );
            this.$store.commit("setShowGlobalAlertError", true);
          }
        })
        .catch(error => {
          this.$store.commit("setMessageGlobalAlertError", error.message);
          this.$store.commit("setShowGlobalAlertError", true);
          console.log("error", error);
        });
    },
    download(path) {
      this.$refs.modalLoading.show();
      let fileName = `qr-code-${this.adminProfile.firstname} ${this.adminProfile.lastname}`;
      let img = new Image();
      img.src = path;
      img.crossOrigin = "Anonymous";

      img.onload = () => {
        let canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        let dataURL = canvas.toDataURL();

        let fileLink = document.createElement("a");
        fileLink.href = dataURL;
        fileLink.setAttribute("download", `${fileName.split("/").pop()}`);
        document.body.appendChild(fileLink);
        fileLink.click();
        this.$refs.modalLoading.hide();
      };
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .btn-main-primary,
::v-deep .btn-outline-primary {
  min-width: 150px !important;
}
.qr-code-none {
  width: 50%;
  height: 50%;
  color: #ebedef;
}
@media (max-width: 767.98px) {
  ::v-deep .btn-main-primary,
  ::v-deep .btn-outline-primary {
    min-width: unset !important;
  }
}
</style>
